import React from 'react';
import {css} from '@emotion/core';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {chromeExtensionLink} from '../../constants';

// @ts-expect-error
import ModalOverlay from '../../components/ModalOverlay';

import MapquestLogoSmall from '../../assets/svg/mapquest-logo-black-small.svg';
import LoadingCircles from '../../assets/svg/loading-circles-blue.svg';

// @ts-expect-error
import Modal from '../../components/Modal';
// @ts-expect-error
import Steps from '../../components/Steps';
// @ts-expect-error
import TermsOfService from '../../components/TermsOfService';
import MapquestLogo from '../../assets/svg/mapquest-logo.svg';
import MarketingLayout from '../../components/MarketingLayout';
import {useCTA} from '../../hooks/useCTA';
import Button from '../../components/Button';

const DirectionsNewTabOffer = () => {
    const {isModalOverlayVisible, onCtaClick} = useCTA({webStoreLink: chromeExtensionLink, isPrimary: false});
    const site = useStaticQuery(graphql`
        query DirectionsNewTabOffer {
            background: file(relativePath: {eq: "mq_default_search_background.jpg"}) {
                childImageSharp {
                    fixed(width: 1200, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            search: file(relativePath: {eq: "bitmap.png"}) {
                childImageSharp {
                    fixed(width: 241, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
            progressbar: file(relativePath: {eq: "progress-bar.png"}) {
                childImageSharp {
                    fixed(width: 483, height: 19, quality: 100) {
                        ...GatsbyImageSharpFixed_noBase64
                    }
                }
            }
        }
    `);

    return (
        <MarketingLayout>
            <section
                className="top-container"
                css={css`
                    max-width: 100%;
                    overflow: hidden;
                    height: 100vh;
                    @media (max-height: 500px) {
                        overflow-y: scroll;
                    }
                `}
            >
                <Img
                    fixed={site.background.childImageSharp.fixed}
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        zIndex: '0'
                    }}
                    imgStyle={{
                        zIndex: '0',
                        position: 'relative'
                    }}
                />
                <Modal maxWidth="890px" marginTop="0px" innerMargin="7%" backgroundColor="inherit" padding="0">
                    <aside
                        css={css`
                            padding: 2rem 3rem 1rem;
                            width: 100%;
                        `}
                    >
                        <h1
                            css={css`
                                font-size: 22px;
                                font-weight: 500;
                                line-height: normal;
                                font-family: 'Roboto', sans-serif;
                                color: #4e4e4e;
                                letter-spacing: 0.05rem;
                                @media (max-width: 600px) {
                                    font-size: 1.5rem;
                                    line-height: normal;
                                }
                            `}
                        >
                            Optional Offer!
                        </h1>
                        <MapquestLogo
                            css={css`
                                float: right;
                                margin-top: -25px;
                                @media (min-width: 400px) and (max-width: 775px) {
                                    margin-top: -50px;
                                    margin-right: -35px;
                                }
                            `}
                        />
                        <p
                            css={css`
                                font-size: 22px;
                                font-weight: 900;
                                font-family: 'Roboto', sans-serif;
                                color: #000;
                                padding: 0 70px;
                                height: 100%;
                                margin: 20px 0 50px;
                                @media (min-width: 400px) and (max-width: 775px) {
                                    margin: 20px 0;
                                    font-size: 15px;
                                    padding: 0 20px;
                                }
                                @media (min-width: 776px) and (max-width: 999px) {
                                    margin: 20px 0;
                                    padding: 0px 50px 30px 0px;
                                    font-size: 16px;
                                }
                                @media (min-width: 1000px) {
                                    margin: 20px 0;
                                    padding: 0px 50px 30px;
                                    font-size: 22px;
                                }
                            `}
                        >
                            Update your browser new tab with Mapquest!
                        </p>
                        <div
                            css={css`
                                width: 100%;
                                @media (min-width: 400px) and (max-width: 550px) {
                                    margin-left: -55px;
                                }
                            `}
                        >
                            <Img
                                fixed={site.progressbar.childImageSharp.fixed}
                                style={{}}
                                imgStyle={{
                                    zIndex: '0',
                                    position: 'relative'
                                }}
                                css={css`
                                    margin: 0 auto;
                                `}
                            />
                        </div>
                        <p
                            css={css`
                                font-size: 14px;
                                font-weight: normal;
                                font-family: 'Roboto', sans-serif;
                                font-weight: 500;
                                color: #2a2a2a;
                                padding: 0 70px;
                                height: 100%;
                                margin: 20px 0 50px;
                                @media (min-width: 400px) and (max-width: 775px) {
                                    margin: 20px 0;
                                    font-size: 14px;
                                    padding: 0 10px;
                                }
                                @media (min-width: 776px) and (max-width: 999px) {
                                    margin: 20px 0;
                                    padding: 0 50px;
                                    font-size: 14px;
                                }
                                @media (min-width: 1000px) {
                                    margin: 20px 0;
                                    padding: 0 50px;
                                    font-size: 14px;
                                }
                            `}
                        >
                            Search MapQuest for directions, route plans, local businesses, restaurants and get easy
                            access to Yahoo <br />
                            search from your browser new tab.
                        </p>
                        <aside>
                            <Img
                                fixed={site.search.childImageSharp.fixed}
                                imgStyle={{
                                    zIndex: '0',
                                    position: 'relative'
                                }}
                                css={css`
                                    display: inline-block;
                                    z-index: 0;
                                    right: 50px;
                                    top: 0px;
                                    @media (max-width: 880px) {
                                        right: -6px;
                                        top: -12px;
                                    }
                                `}
                            />
                            <Steps
                                stepsStyle={css`
                                    width: 465px;
                                    font-size: 22px;
                                    margin: 0 0 auto 0;
                                    line-height: 2.14;
                                    font-family: 'Roboto', sans-serif;
                                    color: #464646;
                                    p {
                                        display: inline-block;
                                        margin-left: 10px;
                                        width: 70%;
                                        float: left;
                                        text-align: left;
                                        line-height: 2.14;
                                        font-family: 'Roboto', sans-serif;
                                        font-size: 22px;
                                        color: #464646;
                                        font-weight: normal;
                                    }
                                    .step {
                                        width: 15%;
                                    }
                                    @media (max-width: 880px) {
                                        width: 440px;
                                        p {
                                            font-size: 16px;
                                        }
                                        margin: 20px 0;
                                        padding: 0 50px;
                                        font-size: 15.5px;
                                    }
                                    span {
                                        font-family: 'Roboto', sans-serif;
                                        color: #464646;
                                        b {
                                            font-family: 'Roboto', sans-serif;
                                            font-weight: 700;
                                            color: #464646;
                                        }
                                    }
                                `}
                                description={[
                                    <span>
                                        Click &quot;<b>Continue</b>&quot;
                                    </span>,
                                    <span>
                                        Click &quot;<b>Add to Chrome</b>&quot;
                                    </span>,
                                    'Enjoy!'
                                ]}
                            />
                        </aside>
                        <p
                            css={css`
                                float: left;
                                margin: 15px 0 0 65px;
                                position: absolute;
                                font-size: 24px;
                                font-family: 'Roboto', sans-serif;
                                color: #6a6a6a;
                                a:hover {
                                    text-decoration: underline;
                                }
                                @media (min-width: 500px) and (max-width: 799px) {
                                    margin: 22px 0 0 -10px;
                                    font-size: 16px;
                                }
                                @media (min-width: 800px) and (max-width: 820px) {
                                    margin: 22px 0 0 -10px;
                                    font-size: 20px;
                                }
                            `}
                        >
                            <a href="https://mapquest.com" rel="noopener noreferrer">
                                NO THANKS
                            </a>
                        </p>
                        <Button
                            text="CONTINUE"
                            ariaLabel="CONTINUE"
                            link={chromeExtensionLink}
                            onClick={onCtaClick}
                            overrideButtonStyles={`
                                    font-size: 24px;
                                    background-color: #0e993e;
                                    color: #fff;
                                    font-family: 'Roboto', sans-serif;
            
                                    &:hover {
                                        background-color: #0e993e;
                                    }
                                    span {
                                        margin: 18px auto 0 auto;
                                        width: 100%;
                                    }
                                    width: 228px;
                                    height: 66px;
                                    padding: 0;
                                    border-radius: 0;
                                    span.continue {
                                        width: 100%;
                                    }
                                    @media (max-width: 600px) {
                                        width: 50%;
                                        padding: 0px 0rem;
                                        margin-left: 20px;
                                    }
                                    
                                `}
                        />
                        <TermsOfService
                            textStyles={`
                                    font-size: .8rem;
                                    color: #797979;
                                    width: 70%;
                                    margin:auto;
                                    font-family: 'Roboto', sans-serif;
                                    a {
                                        color: #797979;
                                        font-family: 'Roboto', sans-serif;
                                        text-decoration: underline;
                                    }`}
                        />
                    </aside>
                </Modal>
            </section>
            <ModalOverlay isVisible={isModalOverlayVisible} maxWidth="300">
                <MapquestLogoSmall />
                <h2
                    css={css`
                        margin: 0.75rem 0;
                        font-weight: 400;
                        font-size: 16px;
                    `}
                >
                    Click &apos; Add to Chrome &apos; button to continue
                </h2>
                <LoadingCircles
                    css={css`
                        width: 100px;
                        height: 100px;
                    `}
                />
            </ModalOverlay>
        </MarketingLayout>
    );
};

export default DirectionsNewTabOffer;

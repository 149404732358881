/* @noflow */
/* eslint-disable camelcase, react/jsx-filename-extension */
import React from 'react';
import {css} from '@emotion/core';
import PropTypes from 'prop-types';
import currentBrowserName from '../util/detectBrowser';

const TermsOfService = ({conjunction, additionalLinks, textStyles, browser = "my browser's"}) => {
    let connector = `, `;

    if (!additionalLinks) {
        connector = ` and `;
    }

    return (
        <p
            css={css`
                ${textStyles}
            `}
        >
            By clicking above and installing the {currentBrowserName()} Extension, I accept and agree to the MapQuest{' '}
            <a href="https://hello.mapquest.com/terms-of-use" target="__blank" rel="noopener noreferrer">
                Terms of Service
            </a>
            {connector}
            <a href="https://system1.com/terms/privacy-policy" target="__blank" rel="noopener noreferrer">
                Privacy Policy
            </a>{' '}
            {conjunction}
            {additionalLinks} and understand that {browser} new tab will be modified.
        </p>
    );
};

TermsOfService.propTypes = {
    conjunction: PropTypes.string,
    additionalLinks: PropTypes.node,
    textStyles: PropTypes.string,
    browser: PropTypes.string
};

TermsOfService.defaultProps = {
    conjunction: '',
    additionalLinks: <></>,
    textStyles: '',
    browser: ''
};

export default TermsOfService;
